<template>
  <div>
    <el-page-header
      :content="getTitle('header')"
      class="cashback-pageHeader"
      @back="goBack"
    />

    <el-button
      class="cashback-operation-btn"
      icon="el-icon-plus"
      type="primary"
      @click="
        action === 'create'
          ? createCashBack()
          : action === 'view'
          ? editCashback()
          : editCashbackClicked()
      "
      size="medium"
      :disabled="
        action === 'create' && cashbackFormData.cashbackConfigs.length === 0
      "
    >
      {{ getTitle("button") }}
    </el-button>
    <el-form
      :rules="formRules"
      :model="cashbackFormData"
      ref="cashbackForm"
      :disabled="mode === 'view'"
    >
      <el-card class="box-card" style="margin-bottom: 10px">
        <div slot="header" class="clearfix">
          <h3 style="margin: unset; display: inline-block">
            Product Cashback Date Range
          </h3>
        </div>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="`Start Date`"
              prop="startDate"
              label-width="200px"
              style="flex-basis: 50%"
            >
              <el-date-picker
                :disabled="
                  isStartDatePassed(originalCashbackFormData.startDate)
                "
                v-model="cashbackFormData.startDate"
                type="datetime"
                placeholder="Select date and time"
                value-format="timestamp"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="`End Date`"
              prop="endDate"
              label-width="200px"
              style="flex-basis: 50%"
            >
              <el-date-picker
                v-model="cashbackFormData.endDate"
                type="datetime"
                placeholder="Select date and time"
                value-format="timestamp"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix cashbackDetailsHeader">
          <h3 style="margin: unset; display: inline-block">
            Cashback Product Details
          </h3>
          <el-button
            v-if="action === 'create'"
            size="medium"
            icon="el-icon-plus"
            type="primary"
            @click="addNewConfig"
            >Add Cashback</el-button
          >
        </div>
        <div
          class="cashbackDetailsWrapper"
          v-for="(config, index) in cashbackFormData.cashbackConfigs"
          :key="index"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label-width="200px"
                style="flex-basis: 50%"
                label="Product Name"
                :prop="'cashbackConfigs[' + index + '].productId'"
              >
                <el-select
                  @change="getProductAvailUnit(config.productId, index)"
                  :filter-method="(query) => productNameChanged(query, index)"
                  :disabled="action !== 'create'"
                  style="width: 100%"
                  v-model="config.productId"
                  filterable
                  placeholder="Product Name"
                  clearable
                  @clear="handleProductsElementsClear(index)"
                >
                  <el-option
                    v-for="item in config.productsResults"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label-width="200px"
                style="flex-basis: 50%"
                label="Product Unit"
                :prop="'cashbackConfigs[' + index + '].unitId'"
              >
                <el-select
                  @change="getAvailProviders(config.unitId, index)"
                  :disabled="action !== 'create'"
                  style="width: 100%"
                  v-model="config.unitId"
                  filterable
                  placeholder="Product Unit"
                  clearable
                >
                  <el-option
                    v-for="item in config.unitsResults"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :prop="'cashbackConfigs[' + index + '].entityIds'"
                label="Select Providers"
                label-width="200px"
              >
                <el-select
                  @change="handleSelectChange($event, index)"
                  :disabled="action !== 'create'"
                  style="width: 100%"
                  v-model="config.entityIds"
                  multiple
                  filterable
                  placeholder="Select Providers"
                  clearable
                >
                  <!-- "Select All" option -->
                  <el-option
                    v-if="config.providersResults.length"
                    :key="config.selectAllOptionKey"
                    :label="config.selectAllLabel"
                    :value="config.selectAllValue"
                  ></el-option>
                  <!-- Iterate over providersResults -->
                  <el-option
                    v-for="item in config.providersResults"
                    :key="item.entityId"
                    :label="item.entityName"
                    :value="item.entityId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item
                :prop="'cashbackConfigs[' + index + '].entityIds'"
                label="Select Providers"
                label-width="200px"
              >
                <el-select
                  :disabled="action !== 'create'"
                  style="width: 100%"
                  v-model="config.entityIds"
                  multiple
                  filterable
                  placeholder="Select Providers"
                  clearable
                >
                  <el-option
                    v-for="item in config.providersResults"
                    :key="item.entityId"
                    :label="item.entityName"
                    :value="item.entityId"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
            </el-col>
            <el-col :span="8">
              <el-form-item
                label-width="200px"
                style="flex-basis: 50%"
                label="Maximum Quantity"
                :prop="'cashbackConfigs[' + index + '].maxPerOrder'"
              >
                <el-input
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                  placeholder="Enter Maximum Quantity"
                  type="number"
                  v-model="config.maxPerOrder"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :prop="'cashbackConfigs[' + index + '].source'"
                label="Promotion Type"
                label-width="200px"
              >
                <el-select
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                  style="width: 100%"
                  v-model="config.source"
                  filterable
                  placeholder="Choose Promotion Type"
                >
                  <el-option label="Fatura" value="fatura"> </el-option>
                  <el-option label="Wholesaler" value="whs"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="Payment Method"
                :prop="'cashbackConfigs[' + index + '].source'"
                label-width="200px"
                style="flex-basis: 50%"
              >
                <el-radio-group
                  v-model="config.paymentMethod"
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                >
                  <el-radio label="cash">Cash</el-radio>
                  <el-radio label="credit">Credit</el-radio>
                  <el-radio label="both">Both</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label-width="200px"
                style="flex-basis: 50%"
                label="Cashback Type"
                :prop="'cashbackConfigs[' + index + '].cashbackType'"
              >
                <el-radio-group
                  v-model="config.cashbackType"
                  size="medium"
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                >
                  <el-radio-button label="fixed">Fixed Rate</el-radio-button>
                  <el-radio-button label="percentage"
                    >Percentage</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label-width="200px"
                style="flex-basis: 50%"
                label="Cashback Amount"
                :prop="'cashbackConfigs[' + index + '].cashbackAmount'"
              >
                <el-input
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                  placeholder="Enter Cashback Amount"
                  type="number"
                  v-model="config.cashbackAmount"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" style="display: flex; justify-content: flex-end">
              <el-form-item v-if="action === 'create'">
                <el-button type="danger" @click="removeConfig(index)"
                  >Delete</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import { utils } from "@/utils";
import { mapGetters } from "vuex";
import { cashbackService } from "@/services/cashback.service.js";
import moment from "moment";
export default {
  name: "SingleProductCashback",
  components: {},
  created() {
    const { action = "", id = "", type = "" } = this.$route.params;
    this.action = action;
    this.cashbackId = id;
    this.cashbackType = type;
  },
  data() {
    const validateDate = (rule, value, callback) => {
      const nowUnixTimeStamp = moment().valueOf();
      const valueUnixTimeStamp = moment(value).valueOf();

      if (valueUnixTimeStamp < nowUnixTimeStamp) {
        callback(
          new Error("Please select a date greater than the current date.")
        );
      } else {
        callback();
      }
    };
    const validateDateComparedToStartDate = (rule, value, callback) => {
      const startDate = moment(this.cashbackFormData.startDate).valueOf();
      const valueUnixTimeStamp = moment(value).valueOf();

      if (valueUnixTimeStamp < startDate) {
        callback(
          new Error("Please select a date greater than the start date.")
        );
      } else {
        callback();
      }
    };
    return {
      prodSearchQuery: "",
      searchTimer: null,

      mode: null,
      isActiveCashback: false,
      cashbackFormData: {
        cashbackConfigs: [
          {
            unitsResults: [],
            providersResults: [],
            productsResults: [],
            entityIds: [],
            productId: "",
            unitId: "",
            cashbackType: "",
            cashbackAmount: "",
            paymentMethod: "",
            maxPerOrder: "",
            source: "",
            selectAllLabel: "Select All",
            selectAllValue: "all",
            selectAllOptionKey: "select-all",
          },
        ],
      },
      originalCashbackFormData: {},
      action: "",
      cashbackId: "",
      cashbackType: "",
      rules: {
        startDate: [
          {
            required: true,
            message: "Please input start date",
            trigger: "blur",
          },
          {
            validator: validateDate,
            trigger: "blur",
          },
        ],
        endDate: [
          {
            required: true,
            message: "Please input end date",
            trigger: "blur",
          },
          {
            validator: validateDate,
            trigger: "blur",
          },
          {
            validator: validateDateComparedToStartDate,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    formRules() {
      const rules = { ...this.rules };
      this.cashbackFormData?.cashbackConfigs?.forEach((config, index) => {
        rules["cashbackConfigs[" + index + "].maxPerOrder"] = [
          {
            required: true,
            message: "Please input Min Transaction Amount",
            trigger: "blur",
          },
          { validator: this.positiveValue, trigger: "blur" },
        ];
        rules["cashbackConfigs[" + index + "].entityIds"] = [
          {
            trigger: "change",
            required: true,
            message: "Please Select The Providers",
          },
        ];
        rules["cashbackConfigs[" + index + "].unitId"] = [
          {
            trigger: "change",
            required: true,
            message: "Please Select The Product Unit",
          },
        ];
        rules["cashbackConfigs[" + index + "].productId"] = [
          {
            trigger: "change",
            required: true,
            message: "Please Select The Product",
          },
        ];

        rules["cashbackConfigs[" + index + "].cashbackAmount"] = [
          {
            required: true,
            message: "Please input Min Transaction Amount",
            trigger: "blur",
          },
          { validator: this.positiveValue, trigger: "blur" },
        ];
        rules["cashbackConfigs[" + index + "].cashbackType"] = [
          {
            required: true,
            message: "Please select Cashback Type",
          },
        ];
        rules["cashbackConfigs[" + index + "].paymentMethod"] = [
          {
            required: true,
            message: "Please select Cashback Payment Method",
          },
        ];
        rules["cashbackConfigs[" + index + "].source"] = [
          {
            required: true,
            message: "Please select Cashback Type",
            trigger: "change",
          },
        ];
      });
      return rules;
    },
  },
  methods: {
    handleSelectChange(event, index) {
      const value = event;
      const config = this.cashbackFormData.cashbackConfigs[index];
      const { providersResults } = config;

      if (value.includes("all")) {
        config.entityIds = providersResults.map((item) => item.entityId);
        config.selectAllLabel = "Deselect All";
        config.selectAllValue = "none";
        config.selectAllOptionKey = "deselect-all";
      } else if (value.includes("none")) {
        config.entityIds = [];
        config.selectAllLabel = "Select All";
        config.selectAllValue = "all";
        config.selectAllOptionKey = "select-all";
      } else if (config.providersResults.length === config.entityIds.length) {
        config.selectAllLabel = "Deselect All";
        config.selectAllValue = "none";
        config.selectAllOptionKey = "deselect-all";
      } else if (
        (value.length > 1 && config.selectAllOptionKey === "deselect-all") ||
        value.length === 0
      ) {
        config.selectAllLabel = "Select All";
        config.selectAllValue = "all";
        config.selectAllOptionKey = "select-all";
      }
    },
    handleProductsElementsClear(index) {
      this.cashbackFormData.cashbackConfigs[index].productsResults = [];
    },
    productNameChanged(query, index) {
      clearTimeout(this.searchTimer);
      this.unitsResults = [];
      this.searchTimer = setTimeout(() => {
        this.searchProduct(query, index);
      }, 1300);

      return;
    },
    async searchProduct(query, index) {
      if (query !== "") {
        try {
          const response = await cashbackService.searchProductByName(query);
          this.cashbackFormData.cashbackConfigs[index].productsResults =
            response.data.data;
        } catch (err) {
          console.log(err);
        }
      }
      return;
    },
    async getProductAvailUnit(value, index) {
      if (this.cashbackFormData.cashbackConfigs[index].unitId)
        this.cashbackFormData.cashbackConfigs[index].unitId = "";

      const unitsResults = await cashbackService.availUnits(value);
      this.cashbackFormData.cashbackConfigs[index].unitsResults =
        unitsResults.data.data.map((unitEl) => unitEl.unit);
    },
    async getAvailProviders(value, index) {
      const { productId, unitId } =
        this.cashbackFormData.cashbackConfigs[index];

      const providersResults = await cashbackService.availProviders({
        productId,
        unitId,
      });
      this.cashbackFormData.cashbackConfigs[index].providersResults =
        providersResults.data.data;
    },
    // ** Validation Methods ** //
    positiveValue(rule, value, callback) {
      if (value <= 0) {
        callback(new Error("Please input positive value"));
      } else {
        callback();
      }
    },

    checkEqual(rule, value, callback) {
      const field = rule.field;
      const startIndex = field.indexOf("[");
      const endIndex = field.indexOf("]");
      const index = parseInt(field.substring(startIndex + 1, endIndex));

      const { minTransactionAmount, maxTransactionAmount } =
        this.cashbackFormData.cashbackConfigs[index];

      if (
        +minTransactionAmount < +maxTransactionAmount &&
        +minTransactionAmount !== +maxTransactionAmount
      ) {
        callback();
      } else {
        if (+minTransactionAmount > +maxTransactionAmount) {
          callback(new Error("Max amount must be greater than min amount"));
        } else {
          callback(
            new Error("max and min transaction amounts cannot be equal")
          );
        }
      }
    },
    // ** Validation Methods ** //

    isStartDatePassed(startDate) {
      const startMoment = moment(startDate);
      const currentDate = moment();
      this.isActiveCashback = startMoment.isBefore(currentDate);
      return startMoment.isBefore(currentDate) && this.action !== "create";
    },

    addNewConfig() {
      this.cashbackFormData.cashbackConfigs.push({
        unitsResults: [],
        providersResults: [],
        productsResults: [],
        entityIds: [],
        productId: "",
        unitId: "",
        cashbackType: "",
        cashbackAmount: "",
        paymentMethod: "",
        maxPerOrder: "",
        source: "",
        selectAllLabel: "Select All",
        selectAllValue: "all",
        selectAllOptionKey: "select-all",
      });
    },
    removeConfig(index) {
      this.cashbackFormData.cashbackConfigs.splice(index, 1);
    },

    getTitle(placement) {
      const titles = {
        view: placement === "header" ? "Cashback Details" : "Edit",
        create: placement === "header" ? "Create Product Cashback" : "Save",
        edit: placement === "header" ? "Edit Cashback" : "Save",
      };
      return titles[this.action] || "Unknown Action";
    },
    goBack() {
      this.$router.push("/cashback/products/");
    },
    buildRequestBody(data) {
      if (this.action == "edit" && data) {
        const hasCashbackConfigs = Array.isArray(data.cashbackConfigs);
        return {
          startDate: data?.startDate ? data?.startDate : undefined,
          endDate: data?.endDate ? data?.endDate : undefined,
          cashbackType: hasCashbackConfigs
            ? data?.cashbackConfigs[0]?.cashbackType
            : undefined,
          source: hasCashbackConfigs
            ? data?.cashbackConfigs[0]?.source
            : undefined,

          cashbackAmount: hasCashbackConfigs
            ? data?.cashbackConfigs[0]?.cashbackAmount
            : undefined,
          maxPerOrder: hasCashbackConfigs
            ? data?.cashbackConfigs[0]?.maxPerOrder
            : undefined,
          paymentMethod: hasCashbackConfigs
            ? data?.cashbackConfigs[0]?.paymentMethod
            : undefined,
        };
      }
      if (this.action == "create") {
        const dataToSent = { ...this.cashbackFormData };
        dataToSent.cashbackConfigs.map((el) => {
          delete el.productsResults;
          delete el.providersResults;
          delete el.unitsResults;
        });
        return dataToSent;
      }
    },
    async createCashBack() {
      this.$refs["cashbackForm"].validate(async (isValid) => {
        try {
          if (isValid) {
            const apiObject = this.buildRequestBody();
            console.log(apiObject, "<<<");
            const response = await cashbackService.createProductCashback(
              apiObject
            );
            if (response && response.status == 201) {
              this.$globalFunctions.popupMessageWrapper(
                "Cashback created successfully",
                "success",
                1500
              );
              this.$router.push("/cashback/products/");
            }
          }
        } catch (error) {
          const errorMessage =
            this.$globalFunctions.errorMessageExtractor(error);
          this.$globalFunctions.popupMessageWrapper(
            errorMessage,
            "error",
            3500
          );
        }
      });
    },
    async getCashbackInfo() {
      try {
        const response = await cashbackService.fetchCashBackInfo({
          cashbackType: this.cashbackType,
          cashbackId: this.cashbackId,
        });
        this.insertCashbackInfo(response.data.data);
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);
        utils.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },
    constructCashbackFormData(data) {
      const {
        cashbackAmount,
        cashbackType,
        maxPerOrder,
        entityId,
        entityName,
        paymentMethod,
        productId,
        productName,
        unitId,
        unitName,
        source,
        startDate,
        endDate,
      } = data;

      const cashbackConfigs = [
        {
          providersResults: [{ entityId, entityName }],
          unitsResults: [{ id: unitId, name: unitName }],
          productsResults: [{ id: productId, name: productName }],
          cashbackAmount,
          cashbackType,
          entityIds: [entityId],
          source,
          maxPerOrder,
          paymentMethod,
          productId,
          unitId,
        },
      ];

      return {
        cashbackConfigs,
        startDate,
        endDate,
      };
    },
    insertCashbackInfo(data) {
      this.cashbackFormData = this.constructCashbackFormData(data);
      this.insertCashbackInfoOriginal(data);
    },
    insertCashbackInfoOriginal(data) {
      this.originalCashbackFormData = this.constructCashbackFormData(data);
    },
    compareCashbackFormData(originalObj, changedObj) {
      const changes = {};
      for (const key in originalObj) {
        if (originalObj.hasOwnProperty(key) && changedObj.hasOwnProperty(key)) {
          // Compare individual properties
          if (
            JSON.stringify(originalObj[key]) !== JSON.stringify(changedObj[key])
          ) {
            changes[key] = changedObj[key];
          }
        }
      }

      // Compare cashbackConfigs array of objects
      const originalConfigs = originalObj.cashbackConfigs || [];
      const changedConfigs = changedObj.cashbackConfigs || [];
      const configChanges = [];

      for (let i = 0; i < originalConfigs.length; i++) {
        const originalConfig = originalConfigs[i];
        const changedConfig = changedConfigs[i];

        // Check if both original and changed configurations exist
        if (originalConfig && changedConfig) {
          // Compare individual properties of the configuration
          const individualChanges = {};
          for (const key in originalConfig) {
            if (
              originalConfig.hasOwnProperty(key) &&
              changedConfig.hasOwnProperty(key)
            ) {
              console.log(JSON.stringify(originalConfig[key]));
              console.log(JSON.stringify(changedConfig[key]));
              if (
                JSON.stringify(originalConfig[key]) !=
                JSON.stringify(changedConfig[key])
              ) {
                individualChanges[key] = changedConfig[key];
              }
            }
          }
          // If there are changes in the configuration, add them to the changes array
          if (Object.keys(individualChanges).length > 0) {
            configChanges.push(individualChanges);
          }
        }
      }

      // If there are changes in cashbackConfigs, add them to the main changes object
      if (configChanges.length > 0) {
        changes.cashbackConfigs = configChanges;
      }
      if (Object.keys(changes).length === 0) {
        return null;
      }
      return changes;
    },

    editCashback() {
      this.$router.push({
        name: "SingleProductCashback",
        params: {
          action: "edit",
          id: this.cashbackId,
          type: this.cashbackType,
        },
      });
      this.mode = "edit";
    },
    editCashbackClicked() {
      if (!this.isActiveCashback) {
        this.$refs["cashbackForm"].validate(async (isValid) => {
          try {
            if (isValid) {
              const apiObject = this.buildRequestBody(
                this.compareCashbackFormData(
                  this.originalCashbackFormData,
                  this.cashbackFormData
                )
              );
              if (!apiObject) {
                this.$globalFunctions.popupMessageWrapper(
                  "No Changes were made to update the cashback.",
                  "error",
                  3500
                );
                return;
              }

              const response = await cashbackService.editCashback({
                formData: apiObject,
                cashbackId: this.cashbackId,
                cashbackType: this.cashbackType,
              });
              if (response && response.status == 200) {
                this.$globalFunctions.popupMessageWrapper(
                  "Cashback edited successfully",
                  "success",
                  1500
                );
                this.$router.push("/cashback/products/");
              }
            }
          } catch (error) {
            console.log("error", error);
            const errorMessage =
              this.$globalFunctions.errorMessageExtractor(error);
            this.$globalFunctions.popupMessageWrapper(
              errorMessage,
              "error",
              3500
            );
          }
        });
        return;
      }
      this.$refs["cashbackForm"].validateField("endDate", async (error) => {
        try {
          if (!error) {
            const apiObject = this.buildRequestBody(
              this.compareCashbackFormData(
                this.originalCashbackFormData,
                this.cashbackFormData
              )
            );
            if (!apiObject) {
              this.$globalFunctions.popupMessageWrapper(
                "No Changes were made to update the cashback.",
                "error",
                3500
              );
              return;
            }

            const response = await cashbackService.editCashback({
              formData: apiObject,
              cashbackId: this.cashbackId,
              cashbackType: this.cashbackType,
            });
            if (response && response.status == 200) {
              this.$globalFunctions.popupMessageWrapper(
                "Cashback edited successfully",
                "success",
                1500
              );
              this.$router.push("/cashback/products/");
            }
          }
        } catch (error) {
          console.log("error", error);
          const errorMessage =
            this.$globalFunctions.errorMessageExtractor(error);
          this.$globalFunctions.popupMessageWrapper(
            errorMessage,
            "error",
            3500
          );
        }
      });
      // this.$refs["cashbackForm"].validateField("endDate", async (isValid) => {
    },
  },
  async mounted() {
    if (this.action === "view") {
      await this.getCashbackInfo();
      this.mode = "view";
    }
    if (this.action === "edit") {
      await this.getCashbackInfo();
    }
    this.$refs.cashbackForm.clearValidate();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "$route.params.action"(newValue, oldValue) {
      this.action = newValue;
      this.mode = newValue;
    },
  },
};
</script>
<style scoped>
.cashbackDetailsWrapper:not(:last-child) {
  position: relative;
  margin-bottom: 52px;
}
.cashbackDetailsWrapper:not(:last-child):after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #cacaca;
  left: 0;
  bottom: -18px;
}
.tNumberHolder {
  position: relative;
  margin-right: 4px;
  padding-left: 22px;
}
.tNumberHolder:before {
  position: absolute;
  content: "T-";
  color: #67c23a;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.cashback-pageHeader {
  border-radius: 4px;
  padding: 12px;
  background: #fff;
  margin-bottom: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.cashback-operation-btn {
  display: block;
  margin-left: auto;
  margin-bottom: 12px;
}
.cashbackDetailsHeader {
  display: flex;
  justify-content: space-between;
}
</style>
